import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SearchBarComponent} from './search-bar/search-bar.component';
import {MatIconModule} from "@angular/material/icon";
import {LoadingComponent} from './loading/loading.component';
import {NgLetDirective} from "./ng-let.directive";
import { ConfirmButtonComponent } from './confirm-button/confirm-button.component';
import {MatButtonModule} from "@angular/material/button";
import {LoadButtonDirective} from "./load-button.directive";
import { DropAreaDirective } from './drop-area.directive';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SearchBarComponent,
        LoadingComponent,
        NgLetDirective,
        ConfirmButtonComponent,
        LoadButtonDirective,
        DropAreaDirective,
    ],
    declarations: [
        SearchBarComponent,
        LoadingComponent,
        NgLetDirective,
        ConfirmButtonComponent,
        LoadButtonDirective,
        DropAreaDirective,
    ]
})
export class SharedModule {
}
