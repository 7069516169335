import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {AuthUtils} from 'app/core/auth/auth.utils';
import {UserService} from 'app/core/user/user.service';
import jwt_decode from "jwt-decode";
import {environment} from "../../../environments/environment";
import {AuthService} from "@auth0/auth0-angular";

@Injectable()
export class InternalAuthService {

    /**
     * Constructor
     */
    constructor(
        private authService: AuthService,
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
        const jwt = jwt_decode(token);
        localStorage.setItem('userRole', JSON.stringify(jwt['https://tokens.reopera.it/metadata']['roles']));
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    get userRole(): string[] {
        return JSON.parse(localStorage.getItem('userRole')) ?? ['user'];
    }

    get getUserId(): number {
        const token = localStorage.getItem('accessToken');
        const jwt = jwt_decode(token);
        const id =  jwt['https://tokens.reopera.it/metadata']['userId'];
        return +id;
    }

    isAdmin(): boolean {
        return this.userRole.indexOf('admin') >= 0;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { email: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in

        return this._httpClient.post('api/auth/sign-in', credentials).pipe(
            switchMap((response: any) => {

                // Store the access token in the local storage
                this.accessToken = response.accessToken;


                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }


    /**
     * Sign out
     */
    signOut(): Observable<boolean> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userRole');
        this.authService.logout()
        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return of(false);
        }

        if (!this.isAdmin()) {
            return of(false);
        }
        // If the access token exists and it didn't expire, sign in using it
        return of(true);
    }
}
