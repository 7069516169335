import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-confirm-button',
    templateUrl: './confirm-button.component.html',
    styleUrls: ['./confirm-button.component.scss']
})
export class ConfirmButtonComponent implements OnInit {
    @Input()
    disabled
    @Input()
    icon: string
    @Input()
    message: string

    @Output()
    onConfirm = new EventEmitter<boolean>()
    active = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    toggle(status: boolean) {
        this.active = status
    }

    confirm() {
        this.onConfirm.emit(true)
    }
}
