import {Route} from '@angular/router';
import {LayoutComponent} from 'app/layout/layout.component';
import { AuthGuard } from '@auth0/auth0-angular';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch: 'full', redirectTo: 'statistics'},
    {
        path: 'companies',
        component: LayoutComponent,
        canActivate:[AuthGuard],
        loadChildren: () => import('app/modules/admin/company/company.module').then(m => m.CompanyModule)

    },
    {
        path: 'workareas',
        component: LayoutComponent,
        canActivate:[AuthGuard],
        loadChildren: () => import('app/modules/admin/workarea/workarea.module').then(m => m.WorkareaModule)

    },
    {
        path: 'history',
        component: LayoutComponent,
        canActivate:[AuthGuard],
        loadChildren: () => import('app/modules/admin/history/history.module').then(m => m.HistoryModule)

    },
    {
        path: 'screeners',
        component: LayoutComponent,
        canActivate:[AuthGuard],
        loadChildren: () => import('app/modules/admin/screeners/screeners.module').then(m => m.ScreenersModule)
    },
    {
        path: 'statistics',
        component: LayoutComponent,
        canActivate:[AuthGuard],
        loadChildren: () => import('app/modules/admin/statistics/statistics.module').then(m => m.StatisticsModule)

    },
    {
        path: 'bundles',
        component: LayoutComponent,
        canActivate:[AuthGuard],
        loadChildren: () => import('app/modules/admin/bundles/bundles.module').then(m => m.BundlesModule)
    },
    {
        path: 'alerts',
        component: LayoutComponent,
        canActivate:[AuthGuard],
        loadChildren: () => import('app/modules/admin/alerts/alerts.module').then(m => m.AlertsModule)
    },
    {
        path: 'users',
        component: LayoutComponent,
        canActivate:[AuthGuard],
        loadChildren: () => import('app/modules/admin/users/users.module').then(m => m.UsersModule)

    },
    {
        path: 'requests',
        component: LayoutComponent,
        canActivate:[AuthGuard],
        loadChildren: () => import('app/modules/admin/requests/requests.module').then(m => m.RequestsModule)

    },
    {
        path: 'services',
        component: LayoutComponent,
        canActivate:[AuthGuard],
        loadChildren: () => import('app/modules/admin/services/services.module').then(m => m.ServicesModule)

    },
    {
        path: 'no-permissions',
        loadChildren: () => import('app/modules/no-permissions/no-permissions.module').then(m => m.NoPermissionsModule)

    }
];
