<button mat-icon-button (click)="toggle(true)" class="" *ngIf="!active; else notActive" [disabled]="disabled">
    <mat-icon [svgIcon]="icon"
              class="mat-icon notranslate icon-size-5 mat-icon-no-color"></mat-icon>
</button>

<ng-template #notActive>
    <button mat-button (click)="confirm()" (focusout)="toggle(false)" (mouseleave)="toggle(false)">
        <span style="color: #e3550a; font-size: 0.75rem">{{message|titlecase}}</span>

    </button>
</ng-template>
