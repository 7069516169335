import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import {FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import { Navigation } from 'app/core/navigation/navigation.types';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { User } from 'app/core/user/user.types';
import { UserService } from 'app/core/user/user.service';

@Component({
    selector     : 'classy-layout',
    templateUrl  : './classy.component.html',
    encapsulation: ViewEncapsulation.None
})
export class ClassyLayoutComponent implements OnInit, OnDestroy
{
    isScreenSmall: boolean;
    navigation: Navigation = {
        default: [
            {
                id   : 'Dashboard',
                title: 'Dashboard',
                type : 'basic',
                icon : 'heroicons_outline:chart-pie',
                link : '/statistics'
            } as FuseNavigationItem,
            {
                id   : 'Clienti',
                title: 'Clienti',
                type : 'basic',
                icon : 'heroicons_outline:office-building',
                link : '/companies'
            } as FuseNavigationItem,
            {
                id   : 'Utenti',
                title: 'Utenti',
                type : 'basic',
                icon : 'heroicons_outline:users',
                link : '/users'
            } as FuseNavigationItem,
            {
                id   : 'Richieste',
                title: 'Richieste',
                type : 'collapsable',
                icon : 'heroicons_outline:calculator',
                // link : '/services',
                children:[
                    {
                        id   : 'Automatiche',
                        title: 'Automatiche',
                        type : 'basic',
                        link : '/requests/automatic'
                    } as FuseNavigationItem,

                    {
                        id: 'Manuali',
                        title: 'Manuali',
                        type: "collapsable",
                        children:[
                            {
                                id   : 'Da evadere',
                                title: 'Da evadere',
                                type : 'basic',
                                link : '/requests/manual'
                            } as FuseNavigationItem,
                            {
                                id   : 'Evase',
                                title: 'Evase',
                                type : 'basic',
                                link : '/requests/manual-completed'
                            } as FuseNavigationItem,
                        ]
                    }as FuseNavigationItem
                    // {
                    //     id   : 'Altre',
                    //     title: 'Altre',
                    //     type : 'basic',
                    //     link : '/requests/others'
                    // } as FuseNavigationItem,
                ]
            } as FuseNavigationItem,
            {
                id   : 'Configurazioni',
                title: 'Configurazioni',
                type : 'collapsable',
                icon : 'feather:settings',
                // link : '/services',
                children:[
                    {
                        id   : 'WorkArea',
                        title: 'WorkArea',
                        type : 'basic',
                        icon : 'heroicons_outline:archive',
                        link : '/workareas',
                    },
                    {
                        id   : 'Bundle',
                        title: 'Bundle',
                        type : 'basic',
                        icon : 'heroicons_outline:puzzle',
                        link : '/bundles',
                    },
                    {
                        id   : 'Servizi',
                        title: 'Servizi',
                        type : 'basic',
                        icon : 'heroicons_outline:ticket',
                        link : '/services',
                    }
                ]
            } as FuseNavigationItem,

            {
                id   : 'Monitoraggi',
                title: 'Monitoraggi',
                type : 'basic',
                icon : 'mat_outline:change_circle',
                link : '/screeners'
            } as FuseNavigationItem,

            {
                id   : 'Alerts',
                title: 'Alerts',
                type : 'basic',
                icon : 'feather:cloud-off',
                link : '/alerts'
            } as FuseNavigationItem,
            {
                id   : 'Storico',
                title: 'Storico',
                type : 'collapsable',
                icon : 'mat_solid:history',
                // link : '/services',
                children:[
                    {
                        id   : 'Sottoscrizioni',
                        title: 'Sottoscrizioni',
                        type : 'basic',
                        link : '/history/subscriptions',
                    }
                ]
            } as FuseNavigationItem,
       ]

    } as Navigation;
    user: User;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number
    {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to navigation data
        // this._navigationService.navigation$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((navigation: Navigation) => {
        //         this.navigation = navigation;
        //     });

        // Subscribe to the user service
        this._userService.user$
            .pipe((takeUntil(this._unsubscribeAll)))
            .subscribe((user: User) => {
                this.user = user;
            });

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {

                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void
    {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if ( navigation )
        {
            // Toggle the opened status
            navigation.toggle();
        }
    }
}
