import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[appDropArea]'
})
export class DropAreaDirective {

    // @HostBinding('class.fileover')
    // fileOver: boolean;
    @Output()
    onFileDropped = new EventEmitter<File>();
    @Output()
    onFileOver = new EventEmitter<boolean>();
    // Dragover listener
    @HostListener('dragover', ['$event']) onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        // this.fileOver = true;
        this.onFileOver.emit(true)
    }

    // Dragleave listener
    // @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    //     evt.preventDefault();
    //     evt.stopPropagation();
    //     // this.fileOver = false;
    //     //this.onFileOver.emit(false)
    //
    // }
    @HostListener('dragleave', ['$event']) onDragLeave(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        // this.fileOver = false;
        this.onFileOver.emit(false)
    }
    // Drop listener
    @HostListener('drop', ['$event']) public ondrop(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        // this.fileOver = false;
        this.onFileOver.emit(false)
        let files = evt.dataTransfer.files;
        if (files.length > 0) {
            this.onFileDropped.emit(files[0]);
        }
    }
}
