import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onSearch = new EventEmitter<string>();
    @Output() onClear = new EventEmitter<boolean>();
    form: FormGroup;
    @Input()
    placeholder: string;
    constructor(private formBuilder: FormBuilder) {
        this.form = this.formBuilder.group({
            search: undefined
        });
    }

    ngOnInit(): void {
    }

    search(): void{
        this.onSearch.emit(this.form.getRawValue().search);
    }

    clear() {
        this.form.patchValue({search: undefined})
        this.onClear.emit(true);
    }
}
